class ProcessingEngineSelector {
  isUsingLegacyEngine: boolean;

  private static localStorageKey = 'PROCESSING_ENGINE';

  constructor() {
    this.isUsingLegacyEngine = this.getSavedSetting();
    this.onChange();
  }

  switchToCloudEngine() {
    this.isUsingLegacyEngine = false;
    this.onChange();
  }

  switchToLegacyEngine() {
    this.isUsingLegacyEngine = true;
    this.onChange();
  }

  private onChange() {
    this.saveSetting();
  }

  /**
   * Reads whether the saved setting (in localStorage) says to use legacyEngine or not
   * If saved setting is not present, defaults to false.
   * @private
   */
  private getSavedSetting(): boolean {
    const readout = localStorage.getItem(
      ProcessingEngineSelector.localStorageKey
    );
    return readout == 'true';
  }

  private saveSetting() {
    if (this.isUsingLegacyEngine) {
      localStorage.setItem(ProcessingEngineSelector.localStorageKey, 'true');
    } else {
      localStorage.setItem(ProcessingEngineSelector.localStorageKey, 'false');
    }
  }
}

export const ProcessingEngineSelectorInstance = new ProcessingEngineSelector();
